.layout-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.skip-to-content-link {
  height: 30px;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
}

.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
  padding: 0 0.5rem;
}

.breadcrumb-item a {
  color: black;
  text-decoration: none;
}

.breadcrumb-item a:hover {
  color: blue;
  text-decoration: underline;
  font-weight: bold;
}

.title {
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: #333333;
}

.nav-bar {
  display: flex;
  align-items: center;
  z-index: 1000;
}
